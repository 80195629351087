

























































.commonHead {
	padding: 0 .3rem;
	background-color: #FFFFFF;
	// height: 0.88rem;
	font-family: PingFang SC;
	position: fixed;
	height: .88rem;
	width: 100%;
	top: 0;
	z-index: 999;
	.head {
		position: relative;
		height: .88rem;
		line-height: .88rem;
	}
	.left {
		position: absolute;
		left: 0;
		top: 0;
		.icon {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
	.title {
		font-family: PingFang SC;
		color: #333333;
		font-size: 0.32rem;
		font-weight: 600;
		text-align: center;
		text-transform: capitalize;
		span {
			font-weight: 600;
		}
	}
	.right {
		position: absolute;
		right: 0;
		top: 0;
		height: .88rem;
		display: flex;
		align-items: center;
		.text {
			color: #666666;
			font-size: .28rem;
		}
		img {
			width: .4rem;
			height: .4rem;
		}
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;