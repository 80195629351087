.commonHead {
  padding: 0 0.3rem;
  background-color: #FFFFFF;
  font-family: PingFang SC;
  position: fixed;
  height: 0.88rem;
  width: 100%;
  top: 0;
  z-index: 999;
}
.commonHead .head {
  position: relative;
  height: 0.88rem;
  line-height: 0.88rem;
}
.commonHead .left {
  position: absolute;
  left: 0;
  top: 0;
}
.commonHead .left .icon {
  width: 0.6rem;
  height: 0.88rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonHead .left .icon img {
  width: 0.4rem;
  height: 0.4rem;
}
.commonHead .title {
  font-family: PingFang SC;
  color: #333333;
  font-size: 0.32rem;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
.commonHead .title span {
  font-weight: 600;
}
.commonHead .right {
  position: absolute;
  right: 0;
  top: 0;
  height: 0.88rem;
  display: flex;
  align-items: center;
}
.commonHead .right .text {
  color: #666666;
  font-size: 0.28rem;
}
.commonHead .right img {
  width: 0.4rem;
  height: 0.4rem;
}
